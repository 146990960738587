export default {
  page: {
    title: {
      base: 'Платіжний шлюз Autopay',
      start: 'Вибір способу оплати',
      visadata: 'Платіж VisaMobile',
      continue: 'Продовжити - Вибір способу оплати',
      wait: 'Очікування',
      confirmation: 'Підтвердження платежу',
      thankYou: 'Дякуємо за оплату',
      error: 'Помилка сторінки',
      errorNotFound: 'Сторінку не знайдено',
      secureBack: 'Завантаження сторінки',
      maintenance: 'Веб-сайт на обслуговуванні',
      regulationsApproval: 'Правила та політика конфіденційності',
      toTransferData: 'переказні реквізити',
      sessionTimeout: 'Сторінка після завершення сесії',
      paywayError: 'Платіжний канал недоступний'
    }
  },
  header: {
    availabilityAdjust: {
      increaseFontSize: 'Збільшити розмір шрифту',
      decreaseFontSize: 'Зменшити розмір шрифту',
      changeContrast: 'Змінити контраст',
      changeLanguage: 'Змінити мову сайту',
      skipToContent: 'Перейти до контенту',
      skipToContentLabel: 'Перейти до основного вмісту',
      logoTitle: 'Логотип сайту'
    },
    sessionTimer: {
      timeOut: 'Сеанс закінчився',
      sessionEnd: 'Сеанс закінчується через:',
      label: 'Продовжити час сеансу',
      button: 'Продовжити'
    }
  },
  footer: {
    text1: 'У Вас є додаткові питання щодо  z  онлайн-платежів або  інших наших послуг? <a title="Відкриється в новому вікні" href="https://pomoc.autopay.pl/?pk_campaign=paywall&pk_kwd=start&pk_medium=hyperlink" class="footer-text-link" target="_blank" rel="noreferrer">Перейдіть на сторінку довідки</a>',
    text2: {
      part1: 'Адміністратором персональних даних є Autopay',
      part2: 'вул. Powstańców Warszawy 6, 81-718 Sopot. Надання персональних даних є добровільним, але необхідним для заповнення поданої заявки. Правова основа, мета, термін обробки персональних даних та права Користувача, а також інша важлива інформація щодо правил обробки персональних даних детально описані на сайті <a href="{privacyPolicyUrl}" class="footer-text-link" target="_blank" title="Перейти на сторінку з документом Політика конфіденційності (128 КБ, PDF)" rel="noreferrer">Політика конфіденційності Autopay S.A.</a>'
    },
    linkShowMoreTitle: 'Показати додаткову інформацію',
    linkShowMoreText: 'Читайте більше',
    linkShowLessTitle: 'Сховати додаткову інформацію',
    linkShowLessText: 'Читайте менше'
  },
  paywall: {
    header: 'Вибрати спосіб оплати',
    info: {
      paywayNotAvailable: 'Вибачте, але схоже, що вибраний спосіб оплати зараз неможна застосувати на Вашому пристрої.',
      applePayNotAvailable: 'Нам шкода, але схоже, що оплата за допомогою Apple Pay на даний момент неможлива на Вашому пристрої.',
      googlePayNotAvailable: 'Нам шкода, але, схоже, оплата за допомогою Google Pay на даний момент неможлива на Вашому пристрої.'
    },
    paywayGroup: {
      inactiveMessage: 'Оплата на даний момент недоступна',
      notice: {
        novelty: 'НОВИНКА',
        promotion: 'ОСОБЛИВА ПРОПОЗИЦІЯ',
        recommended: 'РЕКОМЕНДОВАНО',
        mastercard: 'НАЙБЕЗПЕЧНІША ОПЛАТА В ІНТЕРНЕТІ',
        mastercardC2P: 'НАЙБЕЗПЕЧНІША ОПЛАТА В ІНТЕРНЕТІ',
        blik: 'BLIKOMANIA - Реєструйся та вигравай діаманти!',
        visaMobile: 'Платіть гладко і безпечно'
      },
      bank: {
        many: 'банки',
        one: 'банк',
        two: 'Банки'
      },
      wallet: {
        many: 'гаманців',
        one: 'гаманець',
        two: 'гаманці'
      },
      otp: {
        many: 'шляхи',
        one: 'спосіб',
        two: 'шляхи'
      },
      blikPayLater: {
        info: 'Дізнатися більше'
      }
    },
    paywayList: {
      label: 'Група типів платежу',
      linkSelectOther: 'Вибрати інше'
    },
    paywaySingle: {
      accountAtAnotherBank: 'Маю рахунок в іншому банку'
    },
    paywaySwitch: {
      methodShortSingle: 'Одноразовий',
      methodLongSingle: 'Одноразові виплати',
      methodShortAuto: 'Циклічний',
      methodLongAuto: 'Регулярні платежі'
    },
    form: {
      payway: {
        label: 'Вибрати спосіб оплати'
      },
      card: {
        label: 'Введіть дані картки',
        iframe: {
          title: 'Форма з даними кредитної картки для поповнення'
        }
      },
      visaMobile: {
        iframe: {
          title: 'Форма з телефонним номером для заповнення'
        },
        status: {
          pending: {
            statusText: 'Очікування підтвердження',
            header: 'Підтвердити платіж в застосунку',
            descriptionText1: 'Платіж підтверджується в застосунку Visa Mobile або в банківському застосунку з активною послугою Visa Mobile',
            descriptionText2: 'Після підтвердження транзакції в застосунку, дочекайтеся результату платежу',
            descriptionText3: 'Триває відміна транзакції',
            cancelButton: 'Анулювати платіж'
          },
          success: {
            statusText: 'Дякуємо за покупку!',
            descriptionText: 'Платіж успішний'
          },
          error: {
            statusText: 'Переконайтеся, що на Вашій платіжній карті достатньо коштів для здійснення платежу Visa Mobile.',
            descriptionText: 'Платіж відхилено'
          }
        },
        apps: 'Застосунки, які обслуговують платежі Visa Mobile',
        help: 'Потрібна допомога?',
        info: 'Дізнатися більше',
        secure: 'Безпечний платіж завдяки шифруванню SSL',
        modal2: {
          header: 'Для підтвердження платежу, відкрийте застосунок Visa Mobile або застосунок банку, в якому у Вас активована послуга Visa Mobile',
          listText1: 'у Вас встановлений застосунок Visa Mobile або активована послуга Visa Mobile в мобільному застосунку Вашого банку',
          listText2: 'У Вас увімкнені повідомлення в застосунку Visa Mobile або банківському застосунку з активною послугою Visa Mobile',
          listText3: 'Вказаний Вами номер правильний: <strong class="no-wrap">+{prefix} {phone}</strong>',
          listText4: 'Переконайтеся також, що...'
        }
      },
      click2pay: {
        loaderText: 'Ми шукаємо вашу адресу електронної пошти<br>в <strong>Click to Pay</strong>',
        saveCard: {
          text1: 'Збережіть свою картку',
          text2: 'Ваші дані будуть безпечно збережені в <strong>Mastercard Click to Pay</strong>',
          link: 'Дізнатися більше'
        },
        email: {
          labelForClick2Pay: 'Електронна адреса для сповіщень про платежі'
        },
        cardSelect: {
          selected: 'Вибрано',
          select: 'Вибрати',
          payOther: 'Введіть дані картки',
          back: 'Назад'
        },
        cardSelected: {
          header: 'Використовуйте картку, збережену в Click to Pay',
          date: 'дійсний до',
          buttonChange: 'Змінити'
        },
        codeForm: {
          description: 'Ми знайшли вашу електронну адресу в Click to Pay.',
          codeSentEmail: 'Введіть одноразовий код, який ми надіслали на адресу {email}, щоб увійти у свій обліковий запис.',
          codeSentPhone: 'Введіть одноразовий SMS-код, який ми надіслали на {phone}, щоб увійти до свого облікового запису.',
          codeLabel: 'Введіть код',
          codePlaceholder: 'введіть код',
          codeLabelFlow: 'Код',
          codeSendLabel: 'Надіслати новий код',
          codeSendAriaLabel: 'через',
          codeSendAriaLabel2: 'або',
          buttonSendNewSms: 'SMS',
          buttonSendNewEmail: 'електронна пошта',
          trustedLabel: 'Додайте цей пристрій до свого списку надійних<br><span>Наступного разу ми розпізнаємо цей пристрій, і ви заплатите без входу в систему</span>',
          buttonSubmit: 'Далі',
          buttonChangeCard: 'Введіть дані картки'
        },
        info: {
          description: 'Оплата карткою зашифрована та безпечна. Транзакцію буде авторизовано за допомогою 3DSecure на веб-сайті банку.'
        },
        loginForm: {
          emailLabel: 'Введіть адресу електронної пошти, щоб увійти в Click to Pay',
          emailPlaceholder: 'адреса електронної пошти',
          emailLabelFlow: 'Електронна пошта',
          buttonSubmit: 'Увійти',
          buttonBack: 'Назад'
        },
        newUserForm: {
          header: 'Зберегти картку в Click to Pay',
          description: 'Нам потрібні додаткові дані, щоб зберегти вашу картку.',
          emailLabel: 'Введіть адресу електронної пошти',
          emailPlaceholder: 'адреса електронної пошти',
          emailLabelFlow: 'Електронна пошта',
          countryLabel: 'Країна',
          firstnameLabel: 'Ім\'я',
          firstnamePlaceholder: 'ім\'я',
          firstnameLabelFlow: 'Ім\'я',
          lastnameLabel: 'Прізвище',
          lastnamePlaceholder: 'прізвище',
          lastnameLabelFlow: 'Прізвище',
          phoneLabel: 'Номер телефону',
          phonePlaceholder: 'номер телефону',
          phoneLabelFlow: 'Телефон',
          countryCodePlaceholder: 'код',
          countryCodeLabelFlow: 'Код',
          trustedLabel: 'Додайте цей пристрій до свого списку надійних<br><span>Наступного разу ми розпізнаємо цей пристрій, і ви заплатите без входу в систему</span>',
          TermsLabel: 'Продовжуючи, ви приймаєте <a href="https://www.mastercard.com/global/click-to-pay/country-listing/terms.html" target="_blank">Умови використання</a> і ви розумієте, що ваші дані оброблятимуться відповідно до <a href="https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html" target="_blank">Політика конфіденційності</a>Mastercard.',
          c2pInfo: 'Click to Pay використовуватиме цю інформацію, щоб підтвердити вас і надіслати коди підтвердження на цей номер. Може стягуватися плата за повідомлення/дані.',
          buttonSubmit: 'Зберегти та заплатити',
          buttonBack: 'Оплатити без збереження картки'
        },
        profile: {
          header: 'Увійдіть, щоб оплатити кнопкою',
          buttonChangeEmail: 'Змінити адресу електронної пошти'
        },
        modal: {
          title: 'Click to Pay - інформація',
          header: 'Сплачуйте швидко та безпечно, не переписуючи дані картки за новим стандартом Click to Pay',
          text1: 'Під час оплати карткою шукайте логотип <img src="/web/img/click2pay/Click_to_Pay.svg" style="height: 14px" alt="Click to Pay Logo"> і оплачуйте платіжною карткою швидко і зручно.',
          text2: 'Click To Pay — це новий стандарт оплати карткою, який підтримується Visa та Mastercard.',
          text3: 'Ви можете платити карткою свого рахунку Click to Pay у магазинах у Польщі та за кордоном, не копіюючи дані картки.',
          text4: 'Дані безпечно зберігаються в Visa або Mastercard. Вся інформація повністю зашифрована.',
          text5: 'Під час наступних платежів Click to Pay розпізнає вашу адресу електронної пошти, тож ви зможете негайно оплатити збереженими картками.',
          text6: 'Ви додатково підтверджуєте платежі у своєму банку, що гарантує повну безпеку.',
          processError: 'Під час виконання платежу Click to Pay сталася помилка. Здійснити оплату стандартною карткою.'
        }
      },
      email: {
        label: 'Введіть адресу електронної пошти',
        flowLabel: 'Електронна пошта',
        tip: 'Нам потрібна Ваша електронна адреса, щоб надсилати повідомлення про стан платежу.'
      },
      terms: {
        header: 'Згоди і заяви',
        selectAll: 'Вибрати все',
        fieldNotRequired: 'Необовязкове поле',
        partnerMarketingTextLess: 'Чи Ви хочете отримувати сповіщення електронною поштою, SMS або телефоном про найкращі пропозиції, акції, конкурси та інші цікаві заходи, організовані нами або нашими партнерами?',
        partnerMarketingTextMore: 'Ми обіцяємо не спамувати! Якщо наші повідомлення Вам набриднуть, Ви можете відкликати свою згоду в будь-який момент',
        tradeMarketingTextLess: 'Чи Ви хочете, щоб ми могли дати нашим довіреним партнерам Вашу електронну адресу або телефон, щоб вони могли передавати Вам цікаву інформацію та пропозиції?',
        tradeMarketingTextMore: 'Ми запевняємо Вас, що наші партнери є визнаними компаніями, які, як і ми, не люблять спам, і Ви можете відкликати свою згоду в будь-який час.'
      },
      termsLabelDefault: 'Я ознайомився і приймаю <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Завантажте файл з умовами обслуговування" rel="noreferrer">Правила</a> надання платіжних послуг і <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Завантажте файл із політикою конфіденційності Autopay S.A">Політику конфіденційності</a>. Я хочу негайного надання послуги, і знаю, що у випадку відмови від договору я не матиму права на повернення вартості послуг, наданих на мою вимогу до моменту відмови від договору.',
      termsReccuring: 'Якщо Ви бажаєте здійснити платіж, надайте Вашу згоду на <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Завантажте файл з умовами обслуговування" rel="noreferrer">Правила</a> та <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Завантажте файл із політикою конфіденційності Autopay S.A">Політику конфіденційності</a> Autopay S.A., а також згоду на негайне виконання платіжної послуги і згоду на відрахування коштів з Вашої платіжної карти',
      termsReccuringMore1: 'Погоджуюся на негайне надання послуги, і знаю, що у випадку відмови від договору я не матиму права на повернення вартості послуг, наданих на мою вимогу до моменту відмови від договору.',
      termsReccuringMore2: 'Погоджуюся на регулярне списання товариством Autopay S.A. платежів з моєї платіжної карти, з метою здійснення транзакцій на користь {receiverName}. Умови регулярного списання платежів з карти визначають określa <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Завантажте файл з умовами обслуговування" rel="noreferrer">Правила</a> надання платіжних послуг. Цим повідомляю, що мене було поінформовано відносно строків, частоти та правил визначення сум списання коштів з моєї платіжної карти, а також про порядок відкликання цієї згоди - що було узгоджено мною з {receiverName}. У випадку виникнення будь-яких запитань відносно регулярних платежів з платіжної карти, я звертатимуся до {receiverName}.',
      mwfClauseLess: 'Якщо Ви хочете здійснити платіж, прийняти <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Завантажте файл із Умовами надання послуг" rel="noreferrer">Умови надання послуг</a> i  <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Завантажте файл з політикою конфіденційності Autopay S.A.">Політику  конфіденційності</a> Autopay S.A. та дозвіл на негайне виконання платіжного сервісу.',
      mwfClauseMore: 'Я погоджуюсь, щоб послуга була реалізована негайно, і я знаю, що не отримаю відшкодування понесених витрат у разі розірвання договору.',
      mwfClauseARIA: 'Якщо Ви хочете здійснити платіж, прийняти Умови надання послуг i  Політику  конфіденційності Autopay S.A. та дозвіл на негайне виконання платіжного сервісу. Я погоджуюсь, щоб послуга була реалізована негайно, і я знаю, що не отримаю відшкодування понесених витрат у разі розірвання договору. <a href="{regulationUrl}" class="form-text-link" target="_blank" title="" rel="noreferrer" tabindex="-1">Завантажте файл із Умовами надання послуг</a> <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="" tabindex="-1">Завантажте файл з політикою конфіденційності</a>',
      paymentInfo: 'Платіжне доручення подається через Autopay S.A. з місцезнаходженням у  Сопоті i   буде виконано відповідно до умов, вказаних Вашим банком. Після вибору банку Ви зробите авторизацію платежу.',
      changePaywayLink1: 'Ви все ще можете',
      changePaywayLink2: 'Змінити спосіб оплати',
      linkMore: 'БІЛЬШЕ',
      linkShowMoreTitle: 'Відобразити додаткову інформацію',
      linkLess: 'МЕНШЕ',
      linkShowLessTitle: 'Приховати додаткову інформацію',
      submitButton: {
        visaMobile: 'Оплатити за допомогою',
        fastTransfer: 'Генерувати дані для здійснення платежу',
        mwf: 'Приймаю і оплачую',
        pis: 'Приймаю і розпочинаю оплату',
        auto: 'Замовляйте регулярний платіж',
        autoPayment: 'Оплата замовлення',
        other: 'Оплатити',
        orange: 'Підтвердити',
        c2pNext: 'Далі',
        c2pAccept: 'Я приймаю',
        c2pSaveAndPay: 'Зберігайте та платіть'
      }
    },
    frData: {
      pageTitle: 'Детальні дані для переказу',
      header: 'Доручити переказ, використовуючи вказані  дані',
      nrbLabel: 'Рахунок одержувача',
      nameLabel: 'Назва одержувача',
      addressLabel: 'Адреса одержувача',
      titleLabel: 'Назва переказу',
      amountLabel: 'Сума переказу',
      timeLabel: 'Транзакцію виконати до',
      linkCopy: 'Копіювати',
      linkGetPdf: 'Завантажити PDF з даними',
      linkGetPdfTitle: 'Відкриється в новому вікні',
      linkGoToBank: 'Увійти в банк',
      info: 'Увійдіть до свого банківського рахунку з використанням логіну та паролю. Скопіюйте дані у переказ. Важливо, щоб дані були такими самими, не змінюйте суму, валюту, назву тощо.'
    },
    processing: {
      pageTitle: 'Сторінка очікування обробки транзакції',
      loaderAnimation: 'Анімація, що відображається під час очікування',
      header: 'Ми готуємо Вашу сторінку оплати.',
      description: 'Зачекайте ще трохи...',
      info: {
        header: 'Памятайте!!',
        description: 'Після завершення транзакції не закривайте сайт - якщо Ви це зробите, Ваші гроші не будуть відправлені одержувачу'
      },
      loaderText: 'Пересилання даних триває.<br>Це займе у нас максимум 30  секунд.'
    }
  },
  thankYou: {
    pageTitle: 'Інформація про результат операції',
    form: {
      emailLabel: 'E-mail',
      buttonSubmit: 'Повернутися на сайт партнера'
    },
    negative: {
      header: 'Оплата не була проведена через відсутність авторизації транзакції.',
      description: 'Якщо Ви все ще хочете скористатися пропозицією продавця або постачальника послуг-поверніться на його веб-сайт, виберіть продукт або послугу і зробіть оплату за допомогою обраного Вами методу.'
    },
    pending: {
      header: 'Дякуємо за платіжне доручення через платіжний шлюз.',
      description: 'Перевірка стану транзакції продовжується. Коли процес завершиться, Ви отримаєте інформацію про статус оплати на свою поштову скриньку.'
    },
    positive: {
      header: 'Спасибі, ваш платіж був виконаний.',
      description: 'Підтвердження буде надіслано на вказану Вами адресу електронної пошти.'
    }
  },
  regulationsApproval: {
    header: 'Прийміть транзакцію'
  },
  wait: {
    pageTitle: 'Сторінка очікування'
  },
  sessionTimeout: {
    pageTitle: 'Сторінка після завершення сесії',
    header: 'Час для завершення оплати минув',
    description: 'Ви можете в будь-який час повернутися на сайт партнера, повторно оформити замовлення або скористатися послугою і здійснити оплату за допомогою обраного Вами методу.'
  },
  paywayError: {
    pageTitle: 'Вибраний платіжний канал недоступний',
    header: 'На жаль, вибраний спосіб оплати наразі недоступний.',
    description: 'Поверніться на сторінку партнера та повторіть спробу пізніше.'
  },
  loader: {
    defaultAnimation: 'Під час завантаження сторінки відображається анімація',
    slider: 'Слайдер зі зміною тексту',
    text1: 'Ми прагнемо осягнути вуглецевий нейтралітет. Ви також  це   робите  користуючись нашими послугами.',
    text2: 'Ми їздимо на велосипедах на роботу, обмежуючи викиди парникових газів в атмосферу. Ви теж це робите, користуючись нашими послугами',
    text3: 'Нас живить Зелена енергія. І Вас, коли Ви користуєтеся нашими послугами.',
    text4: 'Ми обмежуємо використання паперу. Ви теж це робите, користуючись нашими послугами.',
    rateLimitExceeded: 'There is increased transaction traffic, so processing your payment may take longer than usual. Please wait and do not refresh the payment page.'
  },
  orderDetails: {
    header: 'Деталі',
    headerAriaLabel: 'Деталі транзакції',
    amountLabel: 'Сума, яку потрібно заплатити,',
    receiverLabel: 'Oдержувач',
    orderLabel: 'Номер замовлення',
    amountOryginalCurrencyLabel: 'Сума в {currency}',
    currencyRateLabel: 'Обмінний курс',
    feeLabel: 'Плата за проведення транзакції',
    customerNumberLabel: 'Номер клієнта',
    invoiceNumberLabel: 'Номер рахунку-фактури',
    accountNumberLabel: 'Номер банківського рахунку',
    paywayLabel: 'Спосіб оплати',
    paywayName: 'Банківський переказ онлайн',
    linkBack: 'Назад в магазин',
    linkBackTitle: 'Залиште операцію та поверніться в магазин',
    linkMore: 'Більше',
    linkLess: 'менш',
    toggleBtnUnfoldAriaLabel: 'Розгорнути деталі замовлення',
    toggleBtnFoldAriaLabel: 'Згорнути деталі замовлення'
  },
  error: {
    pageTitle: 'Сторінка помилки',
    linkBackUrlSet: 'Повернутися на сторінку партнера',
    linkBackUrlNotSet: 'Перейти на сторінку допомоги',
    sessionTimeout: {
      title: 'Час для завершення оплати минув',
      message: 'Ви можете в будь-який час повернутися на сайт партнера, повторно оформити замовлення або скористатися послугою і здійснити оплату за допомогою обраного Вами методу.'
    },
    notFoundError: {
      pageTitle: 'Помилка сторінки 404 - сторінка не знайдена',
      title: 'Схоже, ви зайшли занадто далеко і не змогли знайти сторінку, яку хочете відвідати.',
      message: 'Можливі причини: сторінка не існує або видалена, ведуться роботи з обслуговування, адреса сторінки невірна, сталася технічна помилка.'
    },
    generalError: {
      title: 'Вибачте',
      message: 'На даний момент ми не можемо здійснити Ваш переказ.'
    },
    errFieldNotFound: {
      title: 'Вибачте',
      message: ''
    },
    errBadClientSource: {
      title: 'Вибачте',
      message: ''
    },
    nrParametersError: {
      title: 'Вибачте',
      message: 'На даний момент ми не можемо здійснити Ваш переказ'
    },
    transactionOutdated: {
      title: 'Час для завершення оплати минув.',
      message: 'Доручіть платіж знову.'
    },
    linkValidityTimeOutdated: {
      title: 'Час для завершення оплати минув.',
      message: 'Доручіть платіж знову.'
    },
    transactionValidityTimeOutdated: {
      title: 'Час для завершення оплати минув.',
      message: 'Доручіть платіж знову.'
    },
    multiplyTransaction: {
      title: 'Вибачте',
      message: 'Транзакція вже існує і очікує на оплату'
    },
    transactionCanceled: {
      title: 'Вибачте',
      message1: 'Транзакція анульована.',
      message2: 'Доручіть її виконання ще раз.'
    },
    multiplyPaidTransaction: {
      title: 'Вибачте',
      message: 'Транзакція вже оплачена.'
    },
    transactionReject: {
      title: 'Вибачте',
      message1: 'Упс.., щось пішло не так.',
      message2: 'Ми вже відновлюємо пропускну здатність платіжного шлюзу...'
    },
    bankDisabled: {
      title: 'Вибачте',
      message: 'Вибраний банк недоступний на даний момент.'
    },
    bankTemporaryMaintenance: {
      title: 'Вибачте',
      message: 'На даний момент ми не можемо оплатити ваш переказ.'
    },
    insufficientStartAmount: {
      title: 'Вибачте',
      message: 'Ліміт суми переказу перевищено.'
    },
    startAmountOutOfRange: {
      title: 'Вибачте',
      message: 'Ліміт суми переказу перевищено.'
    },
    nonAccountedLimitExceeded: {
      title: 'Вибачте',
      message: 'Оплата не може бути проведена, тому що продавець досяг щомісячного ліміту продажу.'
    }
  },
  confirmation: {
    pageTitle: 'Інформація про хід платежу',
    linkBack: 'Я повертаюся на сайт',
    linkBackTimeout: 'Повернутися на веб-сайт партнера через',
    incorrectAmount: {
      header: 'Неправильна сума',
      description: 'Ми не перерахували ваш платіж одержувачу, оскільки сума була неправильною.',
      description2: 'Натисніть кнопку, щоб повернутися на сторінку та повторити її. Пам’ятайте, що жодних даних не змінювати'
    },
    multiplePaid: {
      header: 'Подвійний депозит',
      description: 'Одержувач двічі отримав ваш платіж. Не хвилюйтеся – ви отримаєте відшкодування.',
      description2: 'Зв\'яжіться з одержувачем і надайте йому підтвердження платежу.'
    },
    negative: {
      header: 'Кінець часу',
      description: 'Ми не передали ваш платіж одержувачу, оскільки час підтвердження минув.',
      description2: 'Натисніть кнопку, щоб повернутися на сторінку та повторити це.'
    },
    notFound: {
      header: 'Без оплати',
      description: 'Під час обробки вашого платежу сталася помилка. Але нічого не втрачено.',
      description2: 'Натисніть кнопку, щоб повернутися на сторінку та повторити це.'
    },
    outdated: {
      header: 'Кінець часу',
      description: 'Ми не передали ваш платіж одержувачу.',
      description2: 'Натисніть кнопку, щоб повернутися на сторінку та повторити це.'
    },
    outdatedPaidNotRefunded: {
      header: 'Кінець часу',
      description: 'Ми не передали ваш платіж одержувачу. Якщо гроші були зняті з вашого рахунку, не хвилюйтеся, ви отримаєте відшкодування протягом кількох днів.',
      description2: 'Натисніть кнопку, щоб повернутися на сторінку та повторити це.'
    },
    outdatedPaidRefunded: {
      header: 'Кінець часу',
      description: 'Ми не передали ваш платіж одержувачу. Якщо гроші були зняті з вашого рахунку, не хвилюйтеся, ми їх уже повернули.',
      description2: 'Натисніть кнопку, щоб повернутися на сторінку та повторити це.'
    },
    pending: {
      header: 'В процесі',
      description: 'Ми обробляємо ваш платіж. Ми повідомимо вас, коли він дійде до одержувача.'
    },
    positive: {
      header: 'Оплачено',
      visaVideo: 'Рекламне відео, відтворене після успішної транзакції',
      description: 'Ми перерахували ваші кошти одержувачу.'
    }
  },
  messages: {
    accessForbidden: 'Немає доступу',
    internalServerError: 'Трапилася несподівана помилка. Будь ласка, спробуйте пізніше.',
    validators: {
      required: 'Обовязкове поле',
      pattern: 'Укажіть дійсне значення',
      emailRequired: 'Введіть дійсну електронну адресу',
      emailNotValid: 'Введіть дійсну електронну адресу',
      emailNotRecognized: 'Надана електронна адреса не зареєстрована',
      emailIdentityLookup: 'Не вдалося підтвердити адресу електронної пошти',
      emailLocked: 'Надану адресу електронної пошти тимчасово заблоковано в системі Mastercard',
      paywayRequired: 'Для оплати виберіть банк',
      creditCardRequired: 'Заповніть усі поля',
      phoneRequired: 'Будь ласка введіть дійсний номер телефону',
      regulationRequired: 'Прийняти умови',
      requiredConsent: 'Потрібна згода',
      codeNotValid: 'Наданий код недійсний',
      codeAccountLocked: 'Рахунок тимчасово заблоковано в системі Mastercard'
    },
    validatorsAria: {
      required: 'Попередження. Поле форми не заповнено. Обовязкове поле',
      pattern: 'Увага. Поле містить недійсне значення.',
      emailRequired: 'Попередження. Поле електронної пошти не заповнено. Введіть дійсну адресу електронної пошти',
      emailNotValid: 'Попередження. Ваша адреса електронної пошти містить помилку. Введіть дійсну адресу електронної пошти',
      emailNotRecognized: 'Увага. Надана адреса електронної пошти не зареєстрована.',
      emailIdentityLookup: 'Увага. Надану електронну адресу не вдалося перевірити',
      emailLocked: 'Увага. Надана адреса електронної пошти тимчасово заблокована в системі Mastercard.',
      paywayRequired: 'Попередження. Платіж не вибрано. Виберіть спосіб оплати',
      creditCardRequired: 'Попередження. Заповніть усі поля з даними кредитної картки',
      phoneRequired: 'Увага. Поле Номер телефону містить помилку. Вкажіть правильний номер телефону.',
      codeNotValid: 'Увага. Наданий код недійсний.',
      codeAccountLocked: 'Увага. Рахунок тимчасово заблоковано в системі Mastercard'
    },
    success: {
      copy: 'Скопійовано!',
      codeSent: 'Код надіслано!'
    },
    errors: {
      getPdf: 'Не вдалося завантажити  PDF!',
      copy: 'Не вдалося скопіювати!',
      thankYouUpdate: 'Не вдалося записати адресу електронної пошти!',
      groupInactive: 'Наразі неможливо здійснити переказ за допомогою групи {groupName}. Виберіть інший спосіб оплати.',
      paywayInactive: 'Цей спосіб оплати в даний час недоступний. Виберіть інший.',
      googlePayInactive: 'На жаль, Google Pay недоступний на вашому пристрої.',
      applePayInactive: 'Оплата ApplePay вимагає використання браузера Safari і сумісного з цим способом платежу пристрою Apple.',
      codeNotSent: 'Не вдалося повторно надіслати код!',
      codeCounterExceeded: 'Кількість дозволених спроб перевищено. Введіть дані картки вручну або скористайтеся іншим способом оплати.'
    }
  },
  modal: {
    closeButton: 'Закрити',
    confirmButton: 'Я розумію'
  },
  configuration: {
    paywayGroups: {
      auto: {
        label: 'Регулярна оплата',
        description: 'Введіть дані картки, яку ви будете використовувати для оплати. Автоматичні платежі будуть зніматися з вашої картки пізніше.',
        labelInitWithRefund: 'Пробний платіж',
        labelInitWithRefundOrange: 'Додайте платіжну картку',
        labelInitWithPayment: 'Оплата картою',
        descriptionInitWithRefund: 'Вкажіть дані карти, яку ви хочете використати для майбутніх платежів. Наступним кроком ми спишемо з вказаної карти один злотий, щоб перевірити правильність вказаних даних. Після перевірки ми повернемо списану суму.',
        descriptionInitWithPayment: 'Вкажіть дані карти, яку ви використаєте для транзакції та яку ви хочете перевірити для здійснення платежів у майбутньому.'
      },
      blik: {
        label: 'BLIK',
        description: 'Платити відразу, вписуючи код BLIK, без входження і реєстрації'
      },
      card: {
        label: 'Оплата карткою',
        description: 'Оплатіть своєю платіжною карткою.'
      },
      pbl: {
        label: 'Онлайн- переказ',
        description: 'Виберіть банк, з якого ви хочете здійснити платіж',
        descriptionVerify: 'Перевірка особи в режимі онлайн',
        descriptionServiceVerify: 'Виберіть банк, який дозволить вам перевірити онлайн-сервіс'
      },
      visaMobile: {
        label: 'Visa Mobile',
        description: 'Платіж Visa Mobile'
      },
      wallet: {
        label: 'Віртуальний портфель',
        description: 'Оплатити без входження в Інтернет-банкінг'
      },
      installments: {
        label: 'Оплатити в розстрочку',
        description: 'Оплачуйте покупки зручними платежами'
      },
      alior: {
        descriptionInfo1: 'Оплачуйте покупки зручними частинами',
        descriptionInfo2: '(перевірити деталі)',
        aliorModal: {
          text1: 'Залежно від налаштувань веб-сайту, де ви робите покупку, ви можете придбати продукти в розстрочку в таких варіантах:',
          text2: '<ul><li>10 платежів 0%</li><li>20 платежів 0%</li><li>від 3 до 48 платежів із щомісячною вартістю 1% (відповідно до наведеного типового прикладу для даної транзакції)</li></ul>'
        }
      },
      otp: {
        label: 'Оплатити пізніше',
        description: 'Купуйте зараз, платіть потім',
        descriptionInfo1Mwf: 'Оплачуйте рахунок пізніше - до 45 днів одноразово або декількома рівними частинами',
        descriptionInfo1Ecommerce: 'Оплата пізніше - до 45 днів одноразово або декількома рівними частинами',
        descriptionInfo2Mwf: 'Інформація про кредитного посередника та вартість',
        descriptionInfo2Ecommerce: 'Інформація про вартість',
        labelPayka: 'Payka',
        descriptionPayka: 'Оплачуйте рахунок пізніше - одним платежем до 30 днів або 4 рівними частинами <a href="" id="paykaShowInModal">Інформація про кредитного посередника та вартість</a>',
        descriptionBlikPayLater: 'Послуга доступна в Міленіум Банку та ВелоБанку',
        paykaModalMwf: {
          header1: 'Інформація про кредитного посередника',
          text1: 'Компанія Autopay S.A. з місцезнаходженням у Сопоті, яка діє як кредитний посередник Paytree S.A. з місцезнаходженням у Гданську (Кредитор), уповноважена давати клієнтам кредитну пропозицію та перенаправляти на сайт Кредитора, зокрема, на кредитну заявку. Деталі пропозиції за посиланням: <a href="https://payka.pl/rachunki-domowe" target="_blank">Rachunki domowe - Payka</a>.',
          header2: 'Інформація про кошти',
          text2: 'Оплата пізніше - до 45 днів одноразово або декількома рівними частинами. Дані про вартість для репрезентативного прикладу: Погашення протягом 45 днів річна відсоткова ставка 0%; Розстрочка погашення річних 86,83%. Деталі пропозиції на: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        paykaModalEcommerce: {
          header2: 'Інформація про кошти',
          text2: 'Оплата пізніше - до 45 днів одноразово або декількома рівними частинами. Дані про вартість для репрезентативного прикладу: Погашення протягом 45 днів річна відсоткова ставка 0%; Розстрочка погашення річних 86,83%. Деталі пропозиції на: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        blikPayLaterModal: {
          header1: 'Купуйте зараз, платіть протягом 30 днів',
          header2: 'BLIK код',
          text2: 'Ви вводите код BLIK, і ми пропонуємо вам ліміт покупок до 4000 злотих',
          header3: 'Ви купуєте продукти і оплачуєте їх пізніше',
          text3: 'У вас є час перевірити, чи все вас влаштовує',
          header4: 'Ви платите протягом 30 днів безкоштовно',
          text4: 'Ви також можете повернути свої покупки в терміни, вказані магазином'
        }
      },
      companyPayments: {
        label: 'Платежі компанії',
        description: 'Перенести дату оплати покупок компанії на 30 днів'
      },
      transfer: {
        label: 'Дані до грошового переказу',
        description: 'Доручити переказ, використовуючи вказані дані'
      },
      other: {
        label: 'Інше',
        description: ''
      }
    },
    mobileEvents: {
      statusDescription: {
        bankDisabled: 'Вибачте. В даний час ми не можемо здійснити ваш переказ. Обраний банк на даний момент недоступний.',
        blockMultipleTransactions: 'Вибачте. Транзакція вже існує і очікує на оплату',
        blockPaidTransactions: 'Вибачте. Транзакція вже оплачена. Ви не можете зробити платіж знову.',
        canceledTransactions: 'Транзакція, яку Ви намагаєтеся оплатити, була анульована і тому  недоступна. Ми просимо вас розпочати нову транзакцію.',
        declined: 'Відмова в авторизації.',
        generalError: 'Виникла тимчасова проблема з підключенням. На даний момент ми не можемо здійснити Ваш переказ. Запрошуємо пізніше.',
        insufficientStartAmount: 'Вибачте. Ліміт суми переказу був перевищений.',
        internalServerError: 'Упс…. не вдалося : (. Ми працюємо над вирішенням проблеми. Будь ласка, спробуйте пізніше.',
        nonAccountedLimitExceeded: 'Оплата не може бути проведена, тому що продавець досяг щомісячний ліміт продаж.',
        outdatedError: 'Час для завершення оплати минув..',
        paid: 'Транзакція успішно завершена.',
        paywayLoaded: 'Завантажена сторінка швидкого переказу',
        paywaylistLoaded: 'Завантажена сторінка вибору способу оплати'
      }
    }
  },
  maintenance: {
    text1: 'У платіжному шлюзі Autopay триває новорічна актуалізація, тому доручення платежу на даний момент неможливе. Приносимо вибачення за незручності.',
    text2: 'Шлюз працюватиме знову вже 16 січня о 10:00',
    text3: 'Дякуємо за розуміння.'
  },
  requiredParams: {
    accountHolderName: {
      label: 'Дай мені своє ім\'я та прізвище',
      flowLabel: 'ім\'я та прізвище',
      placeholder: 'Дай мені своє ім\'я та прізвище'
    },
    nip: {
      label: 'Введіть свій ідентифікаційний податковий номер',
      flowLabel: 'NIP',
      placeholder: 'Введіть свій ідентифікаційний податковий номер'
    }
  }
}
